import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import "../css/Custom.css";

function Navbar() {
  const thePath = window.location.href;
  var url = new URL(thePath);
  var reference = url.searchParams.get("reference") ?? null;
  var id = url.searchParams.get("id") ?? null;

  const linkGenerator = () => {
    if (reference) {
      if (id) {
        return (
          "https://app.intelligenceforex.com/auth/register-2?reference=" +
          reference +
          "&?referal_id=" +
          id
        );
      } else {
        return (
          "https://app.intelligenceforex.com/auth/register-2?reference=" +
          reference
        );
      }
    } else {
      if (id) {
        return (
          "https://app.intelligenceforex.com/auth/register-2?referal_id=" + id
        );
      } else {
        return "https://app.intelligenceforex.com/auth/register-2";
      }
    }
  };

  useEffect(() => {
    // remove reference available in session storage
    if (sessionStorage.getItem("reference")) {
      sessionStorage.removeItem("reference");
    }
    // save reference if link has reference
    if (reference) {
      sessionStorage.setItem("reference", reference);
    }
  }, []);

  return (
    <nav class="navbar navbar-dark fixed-top bg-darker navbar-expand-lg ">
      <div class="container container-modified ">
        <a href="/" class="navbar-brand">
          <img src={Logo} width="80px" alt="Intelligence FX" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse bg-darker "
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                href="/"
                class="nav-link hvr-underline-from-center pl-5 js-scroll-trigger"
                aria-current="page"
              >
                Home
              </a>
            </li>

            <li class="nav-item">
              <a
                href="/about"
                class="nav-link hvr-underline-from-center"
                aria-current="page"
              >
                About
              </a>
            </li>
            <li class="nav-item">
              <a
                href="/pricing"
                class="nav-link hvr-underline-from-center"
                aria-current="page"
              >
                Pricing
              </a>
            </li>
            <li class="nav-item">
              <a
                href="/bootcamp"
                class="nav-link hvr-underline-from-center"
                aria-current="page"
              >
                Bootcamp
              </a>
            </li>

            <li class="nav-item">
              <a
                href="/services"
                class="nav-link hvr-underline-from-center"
                aria-current="page"
              >
                Services
              </a>
            </li>

            <li class="nav-item">
              <a
                href="https://intelligenceforex.com/faq"
                class="nav-link hvr-underline-from-center"
                aria-current="page"
              >
                FAQs
              </a>
            </li>

            <li class="nav-item">
              <a
                href="http://intelligenceforex.com/blog"
                class="nav-link hvr-underline-from-center"
                aria-current="page"
              >
                Blog
              </a>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Trading floor
              </a>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <a class="dropdown-item" href="/appointment">
                    Book Appointment
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="/tradeclub">
                    Trade Club
                  </a>
                </li>
              </ul>
            </li>

            {/* <li class="nav-item">
                <a href="/" class="nav-link hvr-underline-from-center" aria-current="page">Book Appointment</a>
              </li> */}

            <li class="nav-item">
              <a href="/contact" class="nav-link" aria-current="page">
                Contact
              </a>
            </li>

            <li className="nav-item dropdown" style={{ marginRight: "10px" }}>
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown2"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                signup for free
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="https://app.intelligenceforex.com/auth/login"
                  >
                    Signin
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href={linkGenerator()}>
                    Signup
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a href="/pricing">
                <button
                  type="button"
                  class="btn btn-primary hvr-wobble-top btn-lg px-4 me-md-2"
                >
                  Become a Member
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

import React from 'react';
import MotionInViewCustom from "../components/animate/MotionInViewCustom";


function Faqs() {
  const varFade = () => {
    return {
      inLeft: {
        animate: {x: 0, opacity: 1, transition: {duration: 0.64, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}},
        initial: {x: 120, opacity: 0}
      },
      inLeftSlow: {
        animate: {x: 0, opacity: 1, transition: {duration: 1.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}},
        initial: {x: 120, opacity: 0}
      },
      inUp: {
        initial: {y: 120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}


      },
      inUpSlow: {
        initial: {y: 120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 1.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: 120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}


      },
      inDown: {
        initial: {y: -120, opacity: 0},
        animate: {y: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {y: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}
      },
      inRight: {
        initial: {x: -120, opacity: 0},
        animate: {x: 0, opacity: 1, transition: {duration: 0.94, ease: [0.43, 0.13, 0.23, 0.96]}},
        exit: {x: -120, opacity: 0, transition: {duration: 0.48, ease: [0.43, 0.13, 0.23, 0.96]}}

      },
      upCustom: {
        initial: {
          y: 300,
          rotate: -10
        },
        animate: {
          y: 50,
          rotate: 0,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.8
          }
        }
      },
      upCustom2: {
        initial: {
          y: 300,
          rotate: 10
        },
        animate: {
          y: 50,
          rotate: 0,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 1.8
          }
        }
      }

    }
  }


  return (

    <div className="container col-xxl-9 px-4 py-5 wrapper">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5 mt-5">


        <div class="row mb-5">

          <div class="col-md-12 text-center">
            <MotionInViewCustom
              variants={{
                animate: varFade().inDown.animate,
                initial: varFade().inDown.initial
              }}
              exit={varFade().inDown.exit}
            >

              <p className="textfooter-1">ANSWERS TO</p>
              <h1 className="heading-about">Frequently Asked Questions</h1><br/>
              <h2 className="footerparagraph-2"><a href="../">HOME</a>&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;
                FAQ</h2>
            </MotionInViewCustom>
          </div>

        </div>


        {/* FAQ starts here */}

        <section className="section-one-about">

          <div class="row mb-5">
            <div class="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item mb-5">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne">
                    DO YOU PROVIDE TRAINING AND MENTORSHIP?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                     aria-labelledby="panelsStayOpen-headingOne">
                  <div class="accordion-body footerparagraph-one">
                    Yes we provide training and mentorship in our programs such as <hr/>
                    1.IFX Advanced technical course
                    <hr/>
                    2.IFX Advanced trading psychology course
                    <hr/>
                    3.IFX Bootcamp
                    <hr/>
                    <br/><br/> <a href="/pricing">view packages here</a>
                    <hr/>
                  </div>
                </div>
              </div>

              <div className="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwo">
                    WHAT IS TRAINING AND MENTORSHIP DURATION?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingTwo">
                  <div class="accordion-body">
                    Training duration is determined by how quickly you grasp concepts and
                    your availability;
                    on average, it takes 6 to 8 weeks, but mentorship is lifetime.
                  </div>
                </div>
              </div>

              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThree">
                    WHICH STRATEGY DO YOU TRADE/TEACH?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingThree">
                  <div class="accordion-body">
                    We use our own methodology of speculating the market in logic, flexibility,
                    simplicity,and accurate way that helps us to make a performance phenomenal
                    in all market conditions. The methodologies we use are well researched and
                    have been proven in a market, our results we have been sharing to the public
                    are always reflecting.

                  </div>
                </div>
              </div>

              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFour">
                    Do you offer signal services?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingFour">
                  <div class="accordion-body">
                    No, we do not. Instead, we provide access to LIVE TRADING ROOM,
                    which provides you with access to all of our trades from prior
                    to execution, charts, explanations, all important trading tools,
                    risk strategy,
                    IFX resources, 24/7 support and important trading articles.
                  </div>
                </div>
              </div>


              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive">
                    How much for Livetrading room?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingFive">
                  <div class="accordion-body">
                    The live trading room fee is divided into three plans:
                    one month membership for 115 USD, three month membership
                    for 291 USD, one year membership for 494 USD, and
                    lifetime membership for 849 USD.

                  </div>
                </div>
              </div>


              <div className="accordion-item mb-5">
                <h2 className="accordion-header" id="panelsStayOpen-headingFive-new">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFive-new" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFive-new">
                    WHEN DO YOU PLAN TO HOST A BOOTCAMP ?

                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFive-new" className="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingFive-new">
                  <div className="accordion-body">
                    Every year, we host one bootcamp; in 2023, we want
                    to host a bootcamp in the second quarter of the year.


                  </div>
                </div>
              </div>

              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSix">
                    WHat is your risk to reward ratio (RRR)?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingSix">
                  <div class="accordion-body">
                    Our minimum risk to reward in a trade is 1:10 RRR

                  </div>
                </div>
              </div>


              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSeven">
                    WHAT are your stop loss size (SL)?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingSeven">
                  <div class="accordion-body">
                    Our Stop Loss size range from 5 pips to 20 pips on
                    currency pairs and 20 to 30 in volatile products like
                    xauusd

                  </div>
                </div>
              </div>


              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingEight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEight">
                    WHAT average pips guaranteed?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingEight">
                  <div class="accordion-body">
                    Well, our average pips guarantee is 2000 pips per month
                    and if the target is not reached in an agreed time frame,
                    the member will not be removed from the premium channel.

                  </div>
                </div>
              </div>


              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingNine">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseNine" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseNine">
                    WHAT IS YOUR ACCURACY?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseNine" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingNine">
                  <div class="accordion-body">
                    Our accuracy accuracy percentage is 85% to 95%.
                  </div>
                </div>
              </div>

              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingTen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTen">
                    DO YOU ACCEPT INVESTMENTS?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseTen" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingTen">
                  <div class="accordion-body">
                    We do not accept investment; instead, we specialize in
                    financial market trading on our own capital, financial
                    market training and mentorship, and live trading room
                    service (where people can access our trades the same
                    trades we trade)

                  </div>
                </div>
              </div>


              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingEleven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseEleven" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseEleven">
                    HOW MUCH CAPITAL CAN I USE TO START TRADING?

                  </button>
                </h2>
                <div id="panelsStayOpen-collapseEleven" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingEleven">
                  <div class="accordion-body">
                    It is heavily dependent on the individual's financial
                    situation. How much cash you need depends on your risk
                    appetite, experience, and previous portfolio, but it
                    should not be less than $200 in order to trade with
                    IntelligenceFx's risk plan.
                    However, the true capital to begin with are the skills
                    that IntelligenceFx has dedicated to serving you.

                  </div>
                </div>
              </div>

              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwelve">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwelve" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwelve">
                    WHICH BROKER DO YOU RECCOMEND?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseTwelve" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingTwelve">
                  <div class="accordion-body">
                    We are not recommending brokers but we give you tools
                    in our training to help you research and choose a good
                    one.

                  </div>
                </div>
              </div>


              <div className="accordion-item mb-5">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwelve-new">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwelve-new" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseTwelve-new">
                    INDICES, COMMODITIES AND CRYPTO TRADING?

                  </button>
                </h2>
                <div id="panelsStayOpen-collapseTwelve-new" className="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingTwelve-new">
                  <div className="accordion-body">
                    We trade forex , indices , commodity and cryptos , and you can apply our
                    trading methodology in any market after doing a dipper research and
                    backtesting

                  </div>
                </div>
              </div>

              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingThirteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThirteen" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseThirteen">
                    HOW MANY SETUP IN LIVE TRADING ROOM PER WEEK,DAY OR MONTH
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThirteen" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingThirteen">
                  <div class="accordion-body">
                    0 to 5 setup daily <br/>Accurate entries <br/> Minimum risk to reward ratio (RRR) = 1:10<br/>
                    Average pips per month 2000+ pips (guaranteed) <br/>
                    Accuracy 85 to 95%<br/></div>
                </div>
              </div>


              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingFourteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFourteen" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFourteen">
                    How much do you recommend to risk per trade?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFourteen" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingFourteen">
                  <div class="accordion-body">
                    We highly recommend you to take a risk of 0.2% to 1% of your account balance per trade, <br/>

                    Lot size to be use depend on pips value of pair at a specific period of time, size of stop loss in
                    a specific trade setup and % of risk you want to risk per trade <br/>

                    You can easy calculator lot size to be used by using myfxbook lot
                    size calculator , stinu app and many more other position size calculator
                    sites or apps

                  </div>
                </div>
              </div>


              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingFifteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFifteen" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseFifteen">
                    What time or session do you provide signals?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFifteen" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingFifteen">
                  <div class="accordion-body">
                    There is no specific time or sessions where we send signal take a trade,
                    as we trade according to
                    what price shows us and we take trades when they reach at our entry
                    price regardless to time or session we are in <br/>
                    <strong>why</strong>
                    <br/>
                    Sessions are just a periodic time where a certain pair is expected to
                    have high volatility on the market as economic activities takes place
                    in countries where currency which form a pair come from so that doesn't
                    mean it's a time to take trades

                  </div>
                </div>
              </div>


              <div class="accordion-item mb-5">
                <h2 class="accordion-header" id="panelsStayOpen-headingSixteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseSixteen" aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseSixteen">
                    What are the means of payments you accept?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseSixteen" class="accordion-collapse collapse"
                     aria-labelledby="panelsStayOpen-headingSixteen">
                  <div class="accordion-body">
                    Means of payment we have are <br/><br/>
                    1.Mobile money (mpesa, tigo pesa, halopesa, airtel money, ezypesa for east africa, specifically
                    Tanzania ) <br/>
                    2.Credit or debit card <br/>
                    3.All cryptocurrency . usdt, bitcoin ,etc <br/>
                    4.Skrill <br/>
                    5.Western union <br/>
                    6.World remit <br/>
                    7.Bank transfer. Nmb bank, uba bank, equity bank, absa bank and crdb <br/>
                    8.Selcom pay / mastercard QR <br/></div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="row mt-5 mb-5">
                        <div class="col"></div>
                            <div class="col-md-8 mt-5"> 
                            <div class="faq__accordion">
                                <div class="accordion" id="accordionExample">
                                    
                                    <div class="card">
                                    <div class="card-heading active">
                                        <a data-toggle="collapse" data-target="#collapseOne">
                                            DO YOU OFFER SIGNAL SERVICES?</a>
                                    </div>
                                    <div id="collapseOne" class="collapse show" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p>Yes, we offer signal services which include signal (entry, stop loss, take profit & update), setups, and webinar.</p>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="card ">
                                        <div class="card-heading">
                                            <a data-toggle="collapse" data-target="#collapseTwo">
                                                HOW MUCH FOR SIGNALS?
                                            </a>
                                        </div>
                                        <div id="collapseTwo" class="collapse" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p>Our signals cost are divided into three plans including One Month Premium Membership that is costing 100 USD, 3 Month Premium Membership for 250USD and last is LifeTime Premium Membership for 1200USD.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mb-5">
                                        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        WHAT IS TRAINING AND MENTORSHIP DURATION? 
                                        </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                        <div class="accordion-body">
                                        Training it takes 3 to 6 week depend on how fast a student catch things as training is one to one but mentorship is lifetime
                                        </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                            </div>
                        <div class="col"></div>
                    </div> */}


        </section>


      </div>
    </div>

  );
}

export default Faqs;